import axios from 'axios'
import {getExternalID} from './ItelemedAPI' 

const axiosInstance = axios.create({
  baseURL: 'https://askin-itelemed-api.dermai.com.tw/api/complaints/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${getExternalID()}`
  return config
})

export const getComplaint = (open_hour_id) => {
  return axiosInstance.get(`complaint/${open_hour_id}`).then(res => res.data)
}
