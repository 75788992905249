import "./General.css"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Box, Grid, Typography, Button, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import user_head from '../images/user_head.png'
import * as ItelemedAPI from '../api/ItelemedAPI'

export default function Measurement() {

  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)
  const [measurement, setMeasurement] = useState([])

  useEffect(() => {

    async function getMeasurement() {
      try {
        var response = await ItelemedAPI.getMeasurement()
        setMeasurement([])
        setMeasurement((prevMeasurement) => [...prevMeasurement, ...response])
      } catch (error) {
        if (error.response.status === 500) {
          alert("資料獲取失敗，\n請重新再操作一次，或請聯絡客服人員")
          window.location.reload()
        }
      }
      setIsLoading(false)
    }

    let external_id = searchParams.get("external_id")
    
    if (external_id !== null && external_id !== 'None' && external_id !== '') {
      external_id = atob(external_id)
      sessionStorage.setItem('external_id', external_id)
    }

    setIsLoading(true)
    getMeasurement()

  }, [searchParams])

  const handleBack = (event) => {
    window.location.replace(`https://askin-business.dermai.com.tw/menu/${searchParams.get("belong")}`)
  }

  return (
    <div className="measurement-table" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <div align="center" style={{display: "flex"}}>
              <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
              <Button onClick={handleBack} sx={{position: "absolute", marginLeft: "3%", marginTop: "12%"}}><KeyboardArrowLeft sx={{color: "#FFFFFF", fontSize: "38px"}} /></Button>
              <Typography variant="h5" component="div" align="left" sx={{position: "absolute", color: "#FFFFFF", fontWeight: "bold", marginLeft: "20%", marginTop: "14%"}}>生理量測數據</Typography>
            </div>
          </Grid>
          <Grid item>
            { isLoading ? <div align="center" style={{marginTop: "20vh"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "20px"}}>載入中...</span></div>  :
              measurement.length === 0 ? <Typography variant="h6" component="div" sx={{color: "#474747", fontWeight: "bold", marginTop: "15%"}}>目前尚無量測數據</Typography> :
              <TableContainer component={Paper} sx={{marginTop: "10%"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="measurement-head">時間</TableCell>
                      <TableCell className="measurement-head">體溫</TableCell>
                      <TableCell className="measurement-head">心律</TableCell>
                      <TableCell className="measurement-head">血壓</TableCell>
                      <TableCell className="measurement-head">血氧</TableCell>
                      <TableCell className="measurement-head">體重</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { measurement.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{fontSize: "10px"}}>{row.時間 != null ? row.時間 : '--'}</TableCell>
                        <TableCell sx={{fontSize: "10px"}}>{row.體溫 != null ? row.體溫 : '--'}</TableCell>
                        <TableCell sx={{fontSize: "10px"}}>{row.心率 != null ? row.心率 : '--'}</TableCell>
                        <TableCell sx={{fontSize: "10px"}}>{row.血壓 != null ? row.血壓 : '--'}</TableCell>
                        <TableCell sx={{fontSize: "10px"}}>{row.血氧 != null ? row.血氧 : '--'}</TableCell>
                        <TableCell sx={{fontSize: "10px"}}>{row.體重 != null ? row.體重 : '--'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}