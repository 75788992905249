import { Container } from '@mui/material'
import History from "../components/History"

export default function UserHistory() {
  
  return (
    <Container maxWidth="100%" disableGutters>
      <History />
    </Container>
  )
}