import './App.css';
import { useEffect, useContext } from "react"
import { Container, CircularProgress } from '@mui/material'
import { LiffContext } from './routes/LiffUsage'
import Menu from "./components/Menu"

function App() {

  const liffContext = useContext(LiffContext)

  useEffect(() => {

    if (liffContext.isLogin) {
      return
    } else {
      liffContext.initLIFF(process.env.REACT_APP_LIFF_MENU)
    }

  }, [liffContext])
  
  return (
    <Container maxWidth="100%" disableGutters>
      { liffContext.isLogin ? <Menu /> :
        <div align="center"><br/><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7"}}>載入中...</span></div>
      }
    </Container>
  );
}

export default App;
