import { Container } from '@mui/material'
import Openhour from "../components/Openhour"

export default function UserMeasurement() {

  return (
    <Container maxWidth="100%" disableGutters>
      <Openhour />
    </Container>
  )
}