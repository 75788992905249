import "./General.css"
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Stack, Grid, Typography, CircularProgress, TextField, Input, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowForward, KeyboardArrowLeft } from '@mui/icons-material'
import user_head from '../images/user_head.png'
import * as ItelemedAPI from '../api/ItelemedAPI'

const CustomizedTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#474747',
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #5AA7A7',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid #5AA7A7',
  }
})

export default function Appointment() {

  const navigate = useNavigate()
  const location = useLocation()

  const belong = location.state.belong

  const [isLoading, setIsLoading] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [patientInfo, setPatientInfo] = useState({patient_last_name: "", patient_first_name: "", patient_idNumber: "", patient_birthdate: "", patient_mobile: "", patient_email: ""})
  const [appointmentInfo, setAppointmentInfo] = useState({})
  const [complaintInfo, setComplaintInfo] = useState({})

  useEffect(() => {
    let open_hour_data = location.state.open_hour_data
    let patient_info = location.state.patient_info

    open_hour_data.open_hour_date = open_hour_data.start_date.replaceAll("-", "/")
    open_hour_data.open_hour_time = `${open_hour_data.start_time}-${open_hour_data.end_time}`

    setAppointmentInfo({})
    setAppointmentInfo((prevAppointmentInfo) => ({...prevAppointmentInfo, ...open_hour_data}))

    setPatientInfo({})
    setPatientInfo((prevPatientInfo) => ({...prevPatientInfo, ...patient_info}))

    setComplaintInfo({})
    setComplaintInfo((prevComplaintInfo) => ({...prevComplaintInfo, ...patient_info}))
  }, [])

  const handleChange = (type) => (event) => {
    switch (type) {
      case "lastName":
        setPatientInfo({...patientInfo, patient_last_name: event.target.value})
        setComplaintInfo({...complaintInfo, patient_last_name: event.target.value})
        break
      case "firstName":
        setPatientInfo({...patientInfo, patient_first_name: event.target.value})
        setComplaintInfo({...complaintInfo, patient_first_name: event.target.value})
        break
      case "idNumber":
        setPatientInfo({...patientInfo, patient_idNumber: event.target.value})
        setComplaintInfo({...complaintInfo, patient_idNumber: event.target.value})
        break
      case "birthdate":
        setPatientInfo({...patientInfo, patient_birthdate: event.target.value})
        setComplaintInfo({...complaintInfo, patient_birthdate: event.target.value})
        break
      case "mobile":
        setPatientInfo({...patientInfo, patient_mobile: event.target.value})
        setComplaintInfo({...complaintInfo, patient_mobile: event.target.value})
        break
      case "email":
        setPatientInfo({...patientInfo, patient_email: event.target.value})
        setComplaintInfo({...complaintInfo, patient_email: event.target.value})
        break
      case "description":
        setComplaintInfo({...complaintInfo, patient_description: event.target.value})
        break
      case "image":
        let images = Array.from(event.target.files)
        setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...images])
        break
      case "file":
        let files = Array.from(event.target.files)
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files])
        break
    }
  }

  const handleRemove = (type, index) => (event) => {

    if (type === "image") {
      let currentSelectedImages = selectedImages
      currentSelectedImages.splice(index, 1)

      setSelectedImages([])
      setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...currentSelectedImages])
    } else if (type === "file") {
      let currentSelectedFiles = selectedFiles
      currentSelectedFiles.splice(index, 1)

      setSelectedFiles([])
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...currentSelectedFiles])
    }

  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    let currentAppointmentInfo = appointmentInfo

    let images = []
    let files = []

    await Promise.all(selectedImages.map( async (image, index) => {
        await blobToBase64(image).then(image_base64 => {
          images.push(image_base64)
        })
      })
    )

    await Promise.all(selectedFiles.map( async (file, index) => {
        await blobToBase64(file).then(file_base64 => {
          files.push(file_base64)
        })
      })
    )

    complaintInfo.patient_images = images
    complaintInfo.patient_files = files

    currentAppointmentInfo.complaint = complaintInfo

    try {
      await ItelemedAPI.makeAppointment(currentAppointmentInfo)
      alert("新增預約成功")
      navigate("/history", {state: {belong: belong}, replace: true})
    } catch (error) {
      if (error.response.status === 409) {
        alert("用戶資料重複，\n請檢查輸入資料是否正確")
      } else if (error.response.status === 500) {
        alert("預約失敗，\n請重新再操作一次，或請聯絡客服人員")
      }
      navigate("/openhour", {state: {belong: belong}, replace: true})
    }
  }

  const handleBack = (event) => {
    navigate("/openhour", {state: {belong: belong}, replace: true})
  }

  const blobToBase64 = (url) => {
    return new Promise(resolve => {
      let reader = new FileReader()
      reader.readAsDataURL(url)
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  }

  return (
    <div className="appointment-form" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <div align="center" style={{display: "flex"}}>
              <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
              <Button onClick={handleBack} sx={{position: "absolute", marginLeft: "3%", marginTop: "12%"}}><KeyboardArrowLeft sx={{color: "#FFFFFF", fontSize: "38px"}} /></Button>
              <Typography variant="h5" component="div" align="left" sx={{position: "absolute", color: "#FFFFFF", fontWeight: "bold", marginLeft: "20%", marginTop: "14%"}}>預約諮詢表單</Typography>
            </div>
          </Grid>
          <Grid item>
            { isLoading ? <div align="center" style={{marginTop: "20vh"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "20px"}}>預約中...</span></div>  :
              <Box component="form" onSubmit={handleSubmit} sx={{width: "90%"}}>
                <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "12%", marginLeft: "-1%"}}>預約人資料</Typography>
                <CustomizedTextField required id="patient_last_name" label="姓氏【必填】" variant="standard" margin="normal" placeholder="請填寫諮詢者姓氏" value={patientInfo.patient_last_name} onChange={handleChange("lastName")} fullWidth />
                <br/>
                <CustomizedTextField required id="patient_first_name" label="名字【必填】" variant="standard" margin="normal" placeholder="請填寫諮詢者名字" value={patientInfo.patient_first_name} onChange={handleChange("firstName")} fullWidth />
                <br/>
                <CustomizedTextField required id="patient_idNumber" label="身分證字號【必填】" variant="standard" margin="normal" placeholder="請填寫諮詢者身份證字號" helperText="(例 : A123456789)" value={patientInfo.patient_idNumber} onChange={handleChange("idNumber")} error={!RegExp("^[A-Z]{1}[1-2]{1}[0-9]{8}$").test(patientInfo.patient_idNumber)} fullWidth />
                <br/>
                <CustomizedTextField required id="patient_birthdate" label="出生年月日【必填】" variant="standard" margin="normal" placeholder="請填寫諮詢者出生年月日" helperText="西元年-月-日 (例 : 1990-01-01)" value={patientInfo.patient_birthdate} onChange={handleChange("birthdate")} error={!RegExp("^((19|20)?[0-9]{2}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01]))*$").test(patientInfo.patient_birthdate)} fullWidth />
                <br/>
                <CustomizedTextField required id="patient_mobile" label="聯絡電話【必填】" variant="standard" margin="normal" placeholder="請填寫諮詢者聯絡電話" value={patientInfo.patient_mobile} onChange={handleChange("mobile")} fullWidth />
                <br/>
                <CustomizedTextField id="patient_email" label="聯絡信箱" variant="standard" margin="normal" placeholder="請填寫諮詢者聯絡信箱" value={patientInfo.patient_email} onChange={handleChange("email")} fullWidth />
                <br/>
                <CustomizedTextField required id="patient_description" label="諮詢問題【必填】" variant="standard" margin="normal" placeholder="請填寫諮詢者諮詢問題" helperText="限250字以內" rows={4} onChange={handleChange("description")} fullWidth multiline />
                <br/>
                <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "2%", marginLeft: "-1%"}}>其他文件上傳</Typography>
                <Typography component="div" align="left" sx={{color: "#474747", fontWeight: "bold", fontSize: "10px", marginLeft: "-1%"}}>Android使用者上傳功能需多次選取檔案</Typography>
                <br/>
                <Box component="div" sx={{ display: "flex", justifyContent: "flex-end", border: 0.5, borderColor: "#474747", width: "101%"}}>
                  <span style={{fontSize: "15px", color: "#474747", alignSelf: "center"}}>相關照片</span>
                  <Button className="upload-button" component="label" variant="contained">
                    瀏覽
                    <Input type="file" id="upload-images" inputProps={{accept: "image/*", multiple: true}} onChange={handleChange("image")} sx={{display: "none"}} />
                  </Button>
                </Box>
                <br/>
                <Grid container>
                  { selectedImages.map((image, index) => (
                    <Grid item xs={6} key={index}>
                      <Stack>
                        <Button className="remove-button" onClick={handleRemove("image", index)}>X</Button>
                        <Box component="img" alt="" src={URL.createObjectURL(image)} sx={{width: "100px", height: "100px", alignSelf: "center"}} />
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
                <Box component="div" sx={{ display: "flex", justifyContent: "flex-end", border: 0.5, borderColor: "#474747", width: "101%", marginTop: "5%"}}>
                  <span style={{fontSize: "15px", color: "#474747", alignSelf: "center"}}>相關檔案</span>
                  <Button className="upload-button" component="label" variant="contained">
                    瀏覽
                    <Input type="file" id="upload-files" inputProps={{accept: "application/pdf", multiple: true}} onChange={handleChange("file")} sx={{display: "none"}} />
                  </Button>
                </Box>
                <br/>
                <Grid container>
                  { selectedFiles.map((file, index) => (
                    <Grid item xs={6} key={index}>
                      <Stack>
                        <Button className="remove-button" onClick={handleRemove("file", index)}>X</Button>
                        <Box component="img" alt="" src="https://di2bu62gtprjr.cloudfront.net/pdf-icon.png" sx={{width: "100px", height: "100px", alignSelf: "center"}} />
                        <p>{file.name}</p>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
                <Button className="submit-button" type="submit" variant="outlined"><span style={{fontSize: 15, fontWeight: "bold", marginTop: "3px", marginLeft: "5px"}}>下一步 完成預約 </span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px" }} /></Button>
              </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}