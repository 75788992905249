import "./General.css"
import { useState, useEffect } from "react"
import { useNavigate, useLocation, useSearchParams} from 'react-router-dom'
import moment from "moment"
import 'moment-timezone'
import { Box, Grid, Card, CardContent, Typography, Button, CircularProgress } from '@mui/material'
import { Alarm, ArrowForward, KeyboardArrowLeft } from '@mui/icons-material'
import user_head from '../images/user_head.png'
import {dayToStr} from './GeneralVariables'
import * as ItelemedAPI from '../api/ItelemedAPI'

moment.locale('zh-tw')

export default function Openhour() {

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  var belong = searchParams.get("belong")

  if (belong === null || belong === 'None' || belong === '') {
    if (location.state) {
      belong = location.state.belong
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [openhour, setOpenhour] = useState([])

  useEffect(() => {

    async function getOpenhour() {
      try {
        var response = await ItelemedAPI.getOpenhour()
        setOpenhour([])
        setOpenhour((prevOpenhour) => [...prevOpenhour, ...response])
      } catch (error) {
        if (error.response.status === 500) {
          alert("資料獲取失敗，\n請重新再操作一次，或請聯絡客服人員")
          window.location.reload()
        }
      }
      setIsLoading(false)
    }

    let external_id = searchParams.get("external_id")
    
    if (external_id !== null && external_id !== 'None' && external_id !== '') {
      external_id = atob(external_id)
      sessionStorage.setItem('external_id', external_id)
    }

    setIsLoading(true)
    getOpenhour()

  }, [])

  const handleReserve = (open_hour_data) => async (event) => {

    setIsLoading(true)

    try {
      var response = await ItelemedAPI.checkAppointment(open_hour_data.open_hour_id)
      navigate("/appointment", {state: {belong: belong, open_hour_data: open_hour_data, patient_info: response}, replace: true})
    } catch (error) {
      if (error.response.status === 409) {
        alert("您已預約過此時段")
      } else {
        alert("預約失敗，\n請重新再操作一次，或請聯絡客服人員")
      }
      setIsLoading(false)
    }
  }

  const handleBack = (event) => {
    window.location.replace(`https://askin-business.dermai.com.tw/menu/${belong}`)
  }

  return (
    <div className="openhour-cards" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <div align="center" style={{display: "flex"}}>
              <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
              <Button onClick={handleBack} sx={{position: "absolute", marginLeft: "3%", marginTop: "12%"}}><KeyboardArrowLeft sx={{color: "#FFFFFF", fontSize: "38px"}} /></Button>
              <Typography variant="h5" component="div" align="left" sx={{position: "absolute", color: "#FFFFFF", fontWeight: "bold", marginLeft: "20%", marginTop: "14%"}}>醫師諮詢時段列表</Typography>
            </div>
          </Grid>
          { isLoading ? <div align="center" style={{marginTop: "20vh"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "20px"}}>載入中...</span></div> :
            openhour.length === 0 ? 
            <Typography variant="h6" component="div" sx={{color: "#474747", fontWeight: "bold", marginTop: "15%"}}>目前無可預約時段</Typography> :
            openhour.map((row, index) => (
            <Grid item key={index}>
              <div align="center" style={{display: "flex", marginTop: index === 0 ? "10%" : "5px", marginLeft: "1%", marginRight: "1%"}}>
                <Card className="openhour-card" sx={{ display: 'flex' }}>
                  <Grid container wrap="nowrap" sx={{width: "95%"}}>
                    <Grid item md={8} xs={8}>
                      <CardContent sx={{width: "100%", color: "#474747"}}>
                        <Typography component="div" align="left" sx={{fontWeight: "bold", fontSize: "25px"}}>{row.start_date.replaceAll('-', '/')} {dayToStr[moment(row.start_date).day()]}.</Typography>
                        <Typography component="div" align="left" sx={{display: "flex", fontSize: "17px"}}><Alarm sx={{color: "#5AA7A7"}}/>{row.start_time} - {row.end_time}</Typography>
                        <br/>
                        <Typography component="div" align="left" sx={{fontSize: "15px"}}>科別 : {row.department !== '' ? row.department : '無提供科別資訊'}</Typography>
                        <Typography component="div" align="left" sx={{fontSize: "15px"}}>醫師 : {row.doctor !== '' ? row.doctor : '無提供醫師資訊'}</Typography>
                      </CardContent>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <CardContent sx={{position: "absolute", marginTop: "-2%"}}>
                        <Typography component="div" align="center" sx={{fontWeight: "bold", fontSize: "50px"}}>{row.current_reserves}</Typography>
                        <Typography component="div" align="center" sx={{fontSize: "10px", marginTop: "-15%"}}>已預約人數</Typography>
                        <br/>
                        { (moment().format('YYYY/MM/DD') === moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') && moment().format('HH:mm') > moment(row.end_time, 'HH:mm').tz("Asia/Taipei").format('HH:mm')) ?
                          <Button className="reserve-disabled-button" disabled><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>預 約</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button> : 
                          <Button className="reserve-button" variant="outlined" onClick={handleReserve(row)}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>預 約</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button>
                        }
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}