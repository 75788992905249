import { Container } from '@mui/material'
import Measurement from "../components/Measurement"

export default function UserMeasurement() {

  return (
    <Container maxWidth="100%" disableGutters>
      <Measurement />
    </Container>
  )
}