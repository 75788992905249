import { Container } from '@mui/material'
import Appointment from "../components/Appointment"

export default function UserMeasurement() {

  return (
    <Container maxWidth="100%" disableGutters>
      <Appointment />
    </Container>
  )
}