import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import LiffUsage from './routes/LiffUsage'
import UserOpenhour from './routes/UserOpenhour'
import UserAppointment from './routes/UserAppointment'
import UserHistory from './routes/UserHistory'
import UserMeasurement from './routes/UserMeasurement'

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LiffUsage />}>
          <Route path="/" element={<App />} />
          <Route path="openhour" element={<UserOpenhour />} />
          <Route path="appointment" element={<UserAppointment />} />
          <Route path="history" element={<UserHistory />} />
          <Route path="measurement" element={<UserMeasurement />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
