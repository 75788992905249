import "./General.css"
import { useState, useEffect } from "react"
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import moment from "moment"
import 'moment-timezone'
import { Box, Grid, Card, CardContent, Stack, Modal, Typography, Button, Divider, CircularProgress } from '@mui/material'
import { ArrowForward, Alarm, Looks3, Assignment, Cancel, Stars, KeyboardArrowLeft } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import user_head from '../images/user_head.png'
import {dayToStr} from './GeneralVariables'
import * as ItelemedAPI from '../api/ItelemedAPI'
import * as ComplaintAPI from '../api/ComplaintAPI'

moment.locale('zh-tw')

export default function History() {

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  var belong = searchParams.get("belong")

  if (belong === null || belong === 'None' || belong === '') {
    if (location.state) {
      belong = location.state.belong
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [history, setHistory] = useState([])
  const [complaintInfo, setComplaintInfo] = useState({patient_complaint: {}, complaint_files: [], doctor_description: "", doctor_suggestion: "", doctor_files: []})
  const [openModal, setOpenModal] = useState(false)
  const [openChildModal, setOpenChildModal] = useState(false)
  const [imageLink, setImageLink] = useState("")

  useEffect(() => {

    async function getHistory() {
      try {
        var response = await ItelemedAPI.getHistory()
        setHistory([])
        setHistory((prevHistory) => [...prevHistory, ...response])
      } catch (error) {
        if (error.response.status === 500) {
          alert("資料獲取失敗，\n請重新再操作一次，或請聯絡客服人員")
          window.location.reload()
        }
      }
      setIsLoading(false)
    }

    let external_id = searchParams.get("external_id")
    
    if (external_id !== null && external_id !== 'None' && external_id !== '') {
      external_id = atob(external_id)
      sessionStorage.setItem('external_id', external_id)
    }

    setIsLoading(true)
    getHistory()

  }, [])

  const handleCancel = (open_hour_data, index) => async (event) => {
    if (window.confirm('是否確認取消預約 ?')) {
      try {
        let currentHistory = history
        currentHistory.splice(index, 1)

        setHistory([])
        setHistory((prevHistory) => [...prevHistory, ...currentHistory])

        await ItelemedAPI.cancelAppointment(open_hour_data)

        if (currentHistory.length === 0) {
          navigate("/openhour", {state: {belong: belong}, replace: true})
        }

      } catch (error) {
        if (error.response.status === 404) {
          alert('欲取消之預約不存在，\n請重新再操作一次，或請聯繫客服人員')
        } else if (error.response.status === 500) {
          alert('取消預約失敗，\n請重新再操作一次，或請聯繫客服人員')
        }
      }
    } else {
      return
    }
  }

  const handleDetail = (open_hour_id) => async (event) => {
    async function getComplaint() {
      try {
        var response = await ComplaintAPI.getComplaint(open_hour_id)
        setComplaintInfo({})
        setComplaintInfo((prevComplaintInfo) => ({...prevComplaintInfo, ...response}))
      } catch (error) {
        if (error.response.status === 404) {
          alert('欲查詢之紀錄不存在，\n請重新再操作一次，或請聯繫客服人員')
        } else if (error.response.status === 500) {
          alert("資料獲取失敗，\n請重新再操作一次，或請聯絡客服人員")
        }
      }
      setIsModalLoading(false)
    }

    setOpenModal(true)
    setIsModalLoading(true)
    getComplaint()
  }

  const handleReserve = () => {
    navigate("/openhour", {state: {belong: belong}, replace: true})
  }

  const handlePopup = (event, file_type, file_link) => {

    event.preventDefault()
    event.stopPropagation()

    if (file_type === "image") {
      setImageLink(file_link)
      setOpenChildModal(true)
    } else {
      window.open(file_link, "_blank")
    }
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleChildModalClose = () => {
    setOpenChildModal(false)
  }

  const handleBack = () => {
    window.location.replace(`https://askin-business.dermai.com.tw/menu/${belong}`)
  }

  const modalStyle = {
    position: "absolute",
    width: "90%",
    height: "95%",
    bgcolor: "#FFFFFF",
    marginLeft: "5%",
    marginTop: "5%",
    borderRadius: "0.5rem",
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto"
  }

  const commentStyle = {
    width: "88%",
    height: "100px",
    bgcolor: "#F4F4F4",
    marginLeft: "6%",
    marginTop: "2%",
    borderRadius: "0.5rem"
  }

  const CustomizedModal = styled(Modal)({
    '.MuiBackdrop-root': {
      backgroundColor: "rgba(0, 0, 0, 0.2)"
    }
  })

  return (
    <div className="history-cards" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item>
            <div align="center" style={{display: "flex"}}>
              <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
              <Button onClick={handleBack} sx={{position: "absolute", marginLeft: "3%", marginTop: "12%"}}><KeyboardArrowLeft sx={{color: "#FFFFFF", fontSize: "38px"}} /></Button>
              <Typography variant="h5" component="div" align="left" sx={{position: "absolute", color: "#FFFFFF", fontWeight: "bold", marginLeft: "20%", marginTop: "14%"}}>已預約列表</Typography>
            </div>
          </Grid>
          { isLoading ? <div align="center" style={{marginTop: "20vh"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "20px"}}>載入中...</span></div> :
            history.length === 0 ? 
            <div>
              <Typography variant="h6" component="div" sx={{color: "#474747", fontWeight: "bold", marginTop: "15%"}}>目前尚無預約</Typography>
                <br/>
                <Button className="history-reserve-button" variant="outlined" onClick={handleReserve}><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>預約</span><ArrowForward sx={{ fontSize: 22, fontWeight: "bold", marginLeft: "5px"}} /></Button>
            </div> : 
            history.map((row, index) => (
            <Grid item key={index}>
              <div align="center" style={{display: "flex", marginTop: index === 0 ? "10%" : "5px", marginLeft: "1%", marginRight: "1%"}}>
                <Card className="history-card" sx={{backgroundColor: (moment().format('YYYY/MM/DD') > moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') || (moment().format('YYYY/MM/DD') === moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') && moment().format('HH:mm') > moment(row.end_time, 'HH:mm').tz("Asia/Taipei").format('HH:mm')) || row.open_hour_status === "end") ? "#F0F0F0" : "#FFFFFF"}}>
                  <Grid container>
                    <Grid item md={8} xs={8}>
                      <CardContent sx={{width: "100%", color: "#474747"}}>
                        <Typography component="div" align="left" sx={{fontWeight: "bold", fontSize: "25px"}}>{row.start_date.replaceAll('-', '/')} {dayToStr[moment(row.start_date).day()]}.</Typography>
                        <Typography component="div" align="left" sx={{display: "flex", fontSize: "17px"}}><Alarm sx={{color: "#5AA7A7"}}/>{row.start_time} - {row.end_time}</Typography>
                        <br/>
                        <Typography component="div" align="left" sx={{fontSize: "15px"}}>科別 : {row.department !== '' ? row.department : '無提供科別資訊'}</Typography>
                        <Typography component="div" align="left" sx={{fontSize: "15px"}}>醫師 : {row.doctor !== '' ? row.doctor : '無提供醫師資訊'}</Typography>
                      </CardContent>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <CardContent sx={{width: "100%", marginTop: "-2%"}}>
                        { (moment().format('YYYY/MM/DD') > moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') || (moment().format('YYYY/MM/DD') === moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') && moment().format('HH:mm') > moment(row.end_time, 'HH:mm').tz("Asia/Taipei").format('HH:mm')) || row.open_hour_status === "end") ?
                          <div>
                            <Typography component="div" align="center" sx={{fontWeight: "bold", fontSize: "25px", color: "#474747", marginTop: "3%"}}>已結束</Typography>
                          </div> :
                          <div>
                            <Typography component="div" align="center" sx={{fontWeight: "bold", fontSize: "50px"}}>{row.notified_number}</Typography>
                            <Typography component="div" align="center" sx={{fontSize: "10px", marginTop: "-15%"}}>看診號碼</Typography>
                          </div>
                        }
                      </CardContent>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{width: "100%"}}>
                      <CardContent sx={{marginTop: "-25px", padding: "10px"}}>
                        { (moment().format('YYYY/MM/DD') > moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') || (moment().format('YYYY/MM/DD') === moment(row.start_date, 'YYYY/MM/DD').tz("Asia/Taipei").format('YYYY/MM/DD') && moment().format('HH:mm') > moment(row.end_time, 'HH:mm').tz("Asia/Taipei").format('HH:mm')) || row.open_hour_status === "end") ?
                          <div>
                            <Button className="history-disabled-button" variant="outlined" disabled><Looks3 sx={{ fontSize: 22, fontWeight: "bold"}} /><br/><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>診間進度</span></Button>
                            <Button className="history-button" variant="outlined" onClick={handleDetail(row.open_hour_id)}><Assignment sx={{ fontSize: 22, fontWeight: "bold"}} /><br/><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>諮詢紀錄</span></Button>
                            <Button className="history-disabled-button" variant="outlined" disabled><Cancel sx={{ fontSize: 22, fontWeight: "bold"}} /><br/><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>取消預約</span></Button>
                          </div> :
                          <div>
                            <Button className="history-button" variant="outlined" component="a" href={row.consultative_progress_link}><Looks3 sx={{ fontSize: 22, fontWeight: "bold"}} /><br/><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>診間進度</span></Button>
                            <Button className="history-button" variant="outlined" onClick={handleDetail(row.open_hour_id)}><Assignment sx={{ fontSize: 22, fontWeight: "bold"}} /><br/><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>諮詢紀錄</span></Button>
                            <Button className="history-button" variant="outlined" onClick={handleCancel(row, index)}><Cancel sx={{ fontSize: 22, fontWeight: "bold"}} /><br/><span style={{fontSize: 15, fontWeight: "bold", marginTop: "1px", marginLeft: "5px"}}>取消預約</span></Button>
                          </div>
                        }
                        <CustomizedModal open={openModal} onClose={() => {handleModalClose()}} disableAutoFocus>
                          { isModalLoading ? <div align="center" style={{marginTop: "30vh"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "20px"}}>載入中...</span></div> :
                            <Box sx={modalStyle}>
                              <Button className="remove-button" sx={{fontSize: "20px", marginLeft: "85%"}} onClick={() => {handleModalClose()}}>X</Button>
                              <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginLeft: "5%"}}>諮詢紀錄</Typography>
                              <Divider variant="middle" />
                              <Grid container direction="column">
                                <Grid item>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginTop: "5%", marginLeft: "5%"}}><Stars sx={{marginRight: "1%"}} />本次狀況</Typography>
                                  <Box sx={commentStyle}>
                                    { complaintInfo.doctor_description !== null && complaintInfo.doctor_description !== "" ?
                                      <div style={{padding: "10px", color: "#474747"}}>{complaintInfo.doctor_description}</div> :
                                      <div style={{padding: "10px", color: "#8E8E8E"}}>尚無醫師回覆</div>
                                    }
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginTop: "8%", marginLeft: "5%"}}><Stars sx={{marginRight: "1%"}} />醫師建議</Typography>
                                  <Box sx={commentStyle}>
                                    { complaintInfo.doctor_suggestion !== null && complaintInfo.doctor_suggestion !== "" ?
                                      <div style={{padding: "10px", color: "#474747"}}>{complaintInfo.doctor_suggestion}</div> :
                                      <div style={{padding: "10px", color: "#8E8E8E"}}>尚無醫師回覆</div>
                                    }
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginTop: "10%", marginLeft: "5%"}}><Stars sx={{marginRight: "1%"}} />醫師上傳照片</Typography>
                                    <Grid container>
                                    { complaintInfo.doctor_files.map((file, index) => (
                                      <Grid item xs={6} key={index}>
                                        <Stack>
                                          <Box component="img" alt="" src={file} sx={{width: "100px", height: "100px", alignSelf: "center"}} onClick={(event) => {handlePopup(event, "image", file)}} />
                                        </Stack>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Typography variant="h6" component="div" align="left" sx={{color: "#474747", fontWeight: "bold", marginTop: "10%", marginLeft: "5%"}}>預約資訊</Typography>
                              <Divider variant="middle" />
                              <Modal open={openChildModal} onClose={() => {handleChildModalClose()}} componentsProps={{backdrop: {style: {backgroundColor: "rgba(0, 0, 0, 0.2)"}}}}>
                                <Stack>
                                   <Button sx={{fontSize: "30px", marginLeft: "85%", color: "#FFFFFF", fontWeight: "bold"}} onClick={() => {handleChildModalClose()}}>X</Button>
                                   <Box component="img" alt="" src={imageLink} sx={{maxWidth: "100%", height: "auto"}} />
                                 </Stack>
                              </Modal>
                              <Grid container direction="column" rowSpacing="10px" wrap="nowrap">
                                <Grid item>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginTop: "5%", marginLeft: "5%"}}><Stars sx={{marginRight: "1%"}} />身分證字號&emsp;&emsp;{complaintInfo.patient_complaint.patient_idNumber}</Typography>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginTop: "2%", marginLeft: "5%"}}><Stars sx={{marginRight: "1%"}} />出生年月日&emsp;&emsp;{complaintInfo.patient_complaint.patient_birthdate}</Typography>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginTop: "2%", marginLeft: "5%"}}><Stars sx={{marginRight: "1%"}} />諮詢問題</Typography>
                                  <Box sx={commentStyle}>
                                    <div style={{padding: "10px", color: "#474747"}}>{complaintInfo.patient_complaint.description}</div>
                                  </Box>
                                </Grid>
                                <Grid item>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginLeft: "5%", marginBottom: "2%"}}><Stars sx={{marginRight: "1%"}} />相關上傳照片</Typography>
                                  <Grid container>
                                    { complaintInfo.complaint_files.map((file, index) => (
                                      ( file.file_type === "image" ?
                                        <Grid item xs={6} key={index}>
                                          <Stack>
                                            <Box component="img" alt="" src={file.file_link} sx={{width: "100px", height: "100px", alignSelf: "center"}} onClick={(event) => {handlePopup(event, file.file_type, file.file_link)}} />
                                          </Stack>
                                        </Grid> : null
                                      )
                                    ))}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Typography variant="h7" component="div" align="left" sx={{display: "flex", color: "#474747", marginLeft: "5%", marginBottom: "2%"}}><Stars sx={{marginRight: "1%"}} />相關上傳檔案</Typography>
                                  <Grid container sx={{marginBottom: "5%"}}>
                                    { complaintInfo.complaint_files.map((file, index) => (
                                      ( file.file_type === "pdf" ?
                                        <Grid item xs={6} key={index}>
                                          <Stack>
                                            <Box component="img" alt="" src="https://di2bu62gtprjr.cloudfront.net/pdf-icon.png" sx={{width: "100px", height: "100px", alignSelf: "center"}} onClick={(event) => {handlePopup(event, file.file_type, file.file_link)}} />
                                          </Stack>
                                        </Grid> : null
                                      )
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          }
                        </CustomizedModal>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  )
}