import "./General.css"
import { useState } from "react"
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Card, CardContent, CardMedia, CardActionArea, Typography, CircularProgress } from '@mui/material'
import user_head from '../images/user_head.png'
import openhour from '../images/openhour.png'
import history from '../images/history.png'
import measurement from '../images/measurement.png'

export default function Menu() {

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async (event) => {
    
    setIsLoading(true)

    let target = event.target.alt || event.target.ariaLabel

    if (target === "openhour") {
      navigate("/openhour", {replace: true})
    } else if (target === "history") {
      navigate("/history", {replace: true})
    } else if (target === "measurement") {
      navigate("/measurement", {replace: true})
    }
  }

  return (
    <div className="menu-cards" align="center">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column" rowSpacing="5%" wrap="nowrap">
          <Grid item>
            <div align="center" style={{display: "flex"}}>
              <Box component="img" sx={{width: "100%"}} alt="user_head" src={user_head}/>
              <Typography variant="h5" component="div" sx={{position: "absolute", marginLeft: "5%", marginTop: "12%", color: "#FFFFFF", fontWeight: "bold"}}>iTeleMed</Typography>
            </div>
          </Grid>
          <Grid item>
            <div align="center" style={{display: "flex", marginTop: "5%", marginLeft: "1%", marginRight: "1%"}}>
              <Card className="menu-card">
                <CardActionArea onClick={handleClick}>
                  <CardMedia component="img" alt="openhour" src={openhour} sx={{width: "150%", marginTop: "-32%", marginLeft: "-31%"}} />
                  <CardContent sx={{marginTop:"-48%", marginLeft: "35%", color: "#474747"}}>
                    <Typography component="div" align="left" aria-label="openhour" sx={{fontWeight: "bold", fontSize: "20px"}}>醫師諮詢時段列表</Typography>
                  </CardContent>
                 </CardActionArea>
              </Card>
            </div>
            <div align="center" style={{display: "flex", marginTop: "3%", marginLeft: "1%", marginRight: "1%"}}>
              <Card className="menu-card">
                <CardActionArea onClick={handleClick}>
                  <CardMedia component="img" alt="history" src={history} sx={{width: "150%", marginTop: "-32%", marginLeft: "-31%"}} />
                  <CardContent sx={{marginTop:"-48%", marginLeft: "35%", color: "#474747"}}>
                    <Typography component="div" align="left" aria-label="history" sx={{fontWeight: "bold", fontSize: "20px"}}>已預約列表</Typography>
                  </CardContent>
                 </CardActionArea>
              </Card>
              { isLoading ? <div align="center" style={{position: "absolute", marginTop: "15%", marginLeft: "40%"}}><CircularProgress className="loading-spinner" /><br/><span style={{color: "#5AA7A7", fontSize: "20px"}}>載入中...</span></div>
                : <div></div>
              }
            </div>
            <div align="center" style={{display: "flex", marginTop: "3%", marginLeft: "1%", marginRight: "1%"}}>
              <Card className="menu-card">
                <CardActionArea onClick={handleClick}>
                  <CardMedia component="img" alt="measurement" src={measurement} sx={{width: "150%", marginTop: "-32%", marginLeft: "-31%"}} />
                  <CardContent sx={{marginTop:"-48%", marginLeft: "35%", color: "#474747"}}>
                    <Typography component="div" align="left" aria-label="measurement" sx={{fontWeight: "bold", fontSize: "20px"}}>生理量測數據</Typography>
                  </CardContent>
                 </CardActionArea>
              </Card>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}