import { useState, createContext } from 'react'
import { Outlet } from 'react-router-dom'
import liff from '@line/liff'

export const LiffContext = createContext()

const LiffProvider = ({children}) => {
  let [isLogin, setIsLogin] = useState(false)

  async function initLIFF(liffId) {

    await liff.init({
      liffId: liffId
    })

    if (!liff.isLoggedIn()) {
      await liff.login()
    } else {
      await liff.getProfile()
      .then(profile => {
        sessionStorage.setItem('external_id', profile.userId)
      })
    }

    setIsLogin(true)
  }

  let value = { isLogin, initLIFF }

  return (
    <LiffContext.Provider value={value}>{children}</LiffContext.Provider>
  )
}

export default function LiffUsage() {
  return (
    <LiffProvider>
      <Outlet />
    </LiffProvider>
  )
}