import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: 'https://askin-itelemed-api.dermai.com.tw/api/reservation/',
  headers: {
    Accept: 'application/json'
  }
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${getExternalID()}`
  return config
})

export const getExternalID = () => {
  return sessionStorage.getItem("external_id")
}

export const getOpenhour = () => {
  return axiosInstance.get(`openhour`).then(res => res.data)
}

export const checkAppointment = (open_hour_id) => {
  return axiosInstance.get(`appointment/openhour/${open_hour_id}`).then(res => res.data)
}

export const makeAppointment = (body) => {
  return axiosInstance.post(`appointment`, body).then(res => res.data)
}

export const cancelAppointment = (open_hour_data) => {
  let open_hour_id = open_hour_data.open_hour_id
  let doctor = open_hour_data.doctor
  let department = open_hour_data.department
  let open_hour_date = open_hour_data.start_date.replaceAll('-', '~')
  let open_hour_time = `${open_hour_data.start_time}-${open_hour_data.end_time}`
  return axiosInstance.delete(`appointment/${open_hour_id}-${doctor}-${department}-${open_hour_date}-${open_hour_time}`).then(res => res.data)
}

export const getHistory = () => {
  return axiosInstance.get(`history`).then(res => res.data)
}

export const getMeasurement = () => {
  return axiosInstance.get(`measurement`).then(res => res.data)
}